<template>
  <div>
    <div class="text-xl font-bold">HAVE A SIP OF TEA.</div>
    <div class="pb-2"></div>
    <div>
      Laidback Lu started as an animation project for TV by Taka Kato, who has
      produced animation works such as ROCCO for Disney+ and Kutsudaru for NHK.
    </div>
    <div class="pb-1"></div>
    <div>We have produced a pilot animation.</div>
    <div class="pb-1"></div>
    <div>
      In order to bring this work to the world, we decided to start developing a
      picture book in conjunction with NFT.
    </div>
    <div class="pb-1"></div>
    <div>
      The NFT will be offered in a full on-chain format, which ensures that the
      owner can continue to own the work.
    </div>
    <div class="pb-1"></div>
    <div>
      If NFT purchasers wish, they will receive Laidback Lu's picture book in
      PDF or printed form, depending on the number of NFTs purchased.
    </div>
    <div class="pb-1"></div>
    <div>
      The first batch will be limited to 360 units and will go on sale on May
      20, 2023 at 5:00 p.m. EST.
    </div>
    <div class="pb-1"></div>
    <div>
      Based on the sales results, we aim to deliver the next Laidback Lu world
      as a picture book and produce an animated series.
    </div>
    <div class="pb-1"></div>
  </div>
</template>
