<template>
  <div>
    <div class="text-xl font-bold">QUESTIONS</div>
    <div class="pb-5"></div>
    <div class="text-lg font-bold">
      - フルオンチェーンNFTとはなんでしょうか?
    </div>
    <div class="text-lg">
      主要なNFTはオフチェーンで保管されています。冗⻑化されたIPFS（Inter-Plantary
      File
      System）上にNFTが保存されていても、a）IPFSへのアクセスが弱い、b）発行者が作品保存のためのプライマリサーバーへ
      の支払いを停止した場合、作品が消失する危険性があります。NFTでは、スマートコントラクト上に作品を配置するため、そのようなリスクはありません。
      購入されたNFTの作品は、完全に保護されています。
    </div>
    <div class="pb-5"></div>
    <div class="text-lg font-bold">- オンチェーンNFTはどこにあるのですか?</div>
    <div class="text-lg">
      『へろへろうさぎのルー』のNFTはイーサリアムレイヤー1上にあります。
    </div>
    <div class="pb-5"></div>
    <div class="text-lg font-bold">- NFTは何枚発売されるのですか?</div>
    <div class="text-lg">
      360枚のジェネラティブなNFTを発行します。このNFTは、並行して製作する絵本から抜粋する形で製作
      します。NFT購入者は、購入するNFTの数量に応じてこの絵本に関連するギフトを用意しています。（ギフトを受領するには予めギフトを提供するための個人情報提供に合意して頂く必要があります。）
    </div>
    <div class="pb-5"></div>
    <div class="text-lg font-bold">
      - ギフトとNFT購入数の関係はどうなりますか?
    </div>
    <div class="text-lg">
      購入者の方には、mint数量に合わせて、ギフトを用意しています。
    </div>
     <div class="h-3"></div>
    <div class="text-lg">
      購入数が1枚から5枚の方には今回販売するNFTに連動して製作する絵本のダイジェスト版PDFに、購入枚数に合わせたPDF版のThank you cardを提供します。
    </div>
     <div class="h-3"></div>
     <div class="text-lg">
    どのThank you cardがお手元に届くかは、ご指定できません。こちらがランダムに処理して、送付します。
    </div>
     <div class="text-lg">
    このThank you cardは5種類製作予定です。
    </div>
     <div class="h-3"></div>
     <div class="text-lg">
    購入数が6枚から9枚の方には、NFTに連動する絵本のPDF版と、5種類全てのThank you cardを差し上げます。
     </div>
      <div class="h-3"></div>
     <div class="text-lg">
    購入数が10枚以上の方には、NFTに連動する絵本のPDF版、5種類全てのThank you cardに加えて、実際に紙の絵本を1冊、 ご連絡頂く住所に送付します。
     </div>
    <div class="h-3"></div>
     <div class="text-lg">
     ただ、郵送事情の都合上、送付先には制限があります。詳しくはLaidback Luのホームページをご覧ください。日本国内は送付対象地域です。
     </div>
     <div class="h-3"></div>
     <div class="text-lg">尚、ギフトを受け取って頂くためには、メールでのご連絡が必要になります。</div>
     <div class="text-lg">Laidback Lu Discord（<a href="https://discord.com/channels/1088335404014501908/1093178245391855706" class="underline">https://discord.com/channels/1088335404014501908/1093178245391855706</a>）にある、NFT購入者限定 の#NFT Holdersチャンネルに記載のメールアドレスに、メールで、件名に「Laidback Luギフト希望」と記載して、メールでwalletアドレスを連絡してください。</div>
      <div class="h-3"></div>
     <div class="text-lg">また、購入数が10枚以上で、紙の絵本も希望される方は、上記のメールにwalletアドレス情報と共に、住所、氏名もご連絡ください。</div>
     <div class="text-lg">こちらで皆様のwalletアドレス情報からLaidback Lu NFTの所有数を確認した上で、対象のギフトを送付します。</div>


    <div class="pb-5"></div>
    <div class="text-lg font-bold">- 価格はどうなるのでしょうか?</div>
    <div class="text-lg">オンチェーンNFTは、1枚あたり0.01ETH+ガス代です。</div>
    <div class="pb-5"></div>
    <div class="text-lg font-bold">- NFTは何枚発売されるのですか？</div>
    <div class="text-lg">
      360枚のジェネラティブなNFTを発行します。このNFTは、並行して製作する絵本から抜粋する形で製作
      します。NFT購入者は、購入するNFTの数量に応じてこの絵本に関連するギフトを用意しています。
    </div>
    <div class="text-lg">
      （ギフトを受領するには予めギフトを提供するために個人情報を提供して頂く必要があります。）
    </div>
    <div class="text-lg">
      個人情報の取り扱いについてはプライバシーポリシーをご覧ください。
    </div>
    <div class="pb-5"></div>
    <div class="text-lg font-bold">- 何枚のNFTを購入することができますか?</div>
    <div class="text-lg">
      ウォレットアドレスごとに上限を設定することはありません。
    </div>
    <div class="pb-5"></div>
    <div class="text-lg font-bold">- アローリストに載ることはできますか?</div>
    <div class="text-lg">
      私たちはミントリストを持っておりませんので、直接一般に公開されることはありません。しかし、日本時間2023年5月20日午前6時にLaidback
      Luのミントサイトをオープンする予定です。詳細は『へろへろうさぎのルー』の<a
        class="border-b border-solid"
        target="_blank"
        rel="noopener noreferrer"                                                                        
        href="https://twitter.com/laidback_lu"
        >Twitter</a
      >でお知らせします。
    </div>
    <div class="pb-5"></div>
    <div class="text-lg font-bold">
      - どうすれば希望通りの購入が完了するのですか?
    </div>
    <div class="text-lg">
      日本時間2023年5月20日午前6時以降に『へろへろうさぎのルー』サイトへアクセスしてください。または、日本時間2023年5月21日午前6時にOpenSeaにアクセスしてください。詳細は『へろへろうさぎの
      ルー』の<a
        class="border-b border-solid"
        target="_blank"
        rel="noopener noreferrer"                                                                        
        href="https://twitter.com/laidback_lu"
        >Twitter</a
      >でお知らせします。
    </div>
  </div>
</template>
