
import { defineComponent, ref, computed } from "vue";
import NetworkGate from "@/components/TokenGates/NetworkGate.vue";
import { ChainIdMap, displayAddress } from "@/utils/MetaMask";

import { useStore } from "vuex";
import { ethers, BigNumber } from "ethers";

import {
  useTokenNetworkContext,
  getProvider,
  getTokenContract,
  getCurrentToken,
  getTokenData,
  useTokenList,
  useMint,
} from "@/utils/utils";

import {
  network,
  tokenAddress,
} from "@/config/project";

export default defineComponent({
  name: "HomePage",
  components: {
    NetworkGate,
  },
  props: ["network"],
  setup(props) {
    const store = useStore();
    
    const chainId = ChainIdMap[network];

    const { networkContext } = useTokenNetworkContext(chainId, tokenAddress);
    store.state.networkContext = networkContext;

    const alchemyKey = process.env.VUE_APP_ALCHEMY_API_KEY;
    const provider = getProvider(network, alchemyKey);
    const tokenContract = getTokenContract(tokenAddress, provider);

    const {
      items,
      updateTokenFromCurrent,
      sortedItem,
    } = useTokenList(tokenContract, true);
    updateTokenFromCurrent();

    const mintCost = "0.01";
    const mintErrorCallback = (e: any) => {
      alert("sorry error.")
    };
    const {
      mint,
      minting
    } = useMint(networkContext, mintCost, mintErrorCallback)
    
    provider.once("block", () => {
      tokenContract.on(
        tokenContract.filters.Transfer(),
        async (from, to, tokenId) => {
          // isMinting.value = false;
          console.log("*** event.Transfer calling fetchTokens", from, to, tokenId?.toNumber());
          if (from && to && tokenId) {
            const currentToken =  tokenId?.toNumber();
            items.value[currentToken] = await getTokenData(tokenContract, currentToken);
            items.value[currentToken].tokenId = currentToken;
            minting.value = false;
          }
        }
      );
    });

    return {
      chainId,
      mint,
      minting,
      sortedItem,
    };
  },
});
