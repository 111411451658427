<template>
  <div>
    <div class="text-xl font-bold">ここらでお茶を一口。</div>
    <div class="pb-2"></div>
    <div>
      『へろへろうさぎのルー』は、Disney+の『ROCCO』にショーランナーとして、
      NHKの『くつだる。』に監督としてアニメーション作品を制作してきた加藤タカ氏によるプロジェクトです。
    </div>
    <div class="pb-1"></div>
    <div>これまでに90秒のパイロットアニメーションを制作しました。</div>
    <div class="pb-1"></div>
    <div>
      ここから実際のアニメーション制作には⻑い道のりですが、その第一歩として、NFTと連動して絵本を製作することにしました。
    </div>
    <div class="pb-1"></div>
    <div>
      このNFTは、所有者が作品を所有し続けられるように、完全オンチェーン方式で提供します。
    </div>
    <div class="pb-1"></div>
    <div>
      NFT購入者が希望すれば、NFTの購入数に応じて、『へろへろうさぎのルー』の絵本をPDFまたは印刷物でお届けします。
    </div>
    <div class="pb-1"></div>
    <div>
      第1弾は360個限定で、2023年5月21日午前6時（日本時間）に発売します。
    </div>
    <div class="pb-1"></div>
    <div>
      NFT展開と連動して絵本が完成すれば、この絵本を世界で出版し、『へろへ
      ろうさぎのルー』を広げて行きます。
    </div>
    <div class="pb-1"></div>
    <div>
      この絵本出版を次のステップとして、将来的には、アニメーション作品の制作を目指します。
    </div>
    <div class="pb-1"></div>
  </div>
</template>
