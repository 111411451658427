import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "mt-2" }
const _hoisted_2 = { class: "mx-8 text-left" }
const _hoisted_3 = { class: "mx-8 text-left" }
const _hoisted_4 = { class: "m-8" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["href"]
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NetworkGate = _resolveComponent("NetworkGate")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_NetworkGate, { expectedNetwork: _ctx.chainId }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("button", {
            class: "mt-2 inline-block rounded bg-sky-400 px-6 py-2.5 leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-sky-600 hover:shadow-lg focus:bg-sky-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-sky-800 active:shadow-lg",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.mint && _ctx.mint(...args)))
          }, _toDisplayString(_ctx.minting ? "Minting" : "Mint"), 1)
        ]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("mint.mintAlertTitle")), 1),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("mint.mintAlertBody")), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.sortedItem.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Loading... "))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedItem, (v, k) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: k,
                    class: "mt-2"
                  }, [
                    _createElementVNode("div", null, _toDisplayString(v.name), 1),
                    _createElementVNode("a", {
                      href: `https://opensea.io/assets/ethereum/0xac644987601456554272b296936d3c262a7a1fcf/${v.tokenId}`
                    }, [
                      _createElementVNode("img", {
                        src: v.image,
                        class: "w-36 border-2 mt-2"
                      }, null, 8, _hoisted_8)
                    ], 8, _hoisted_7)
                  ]))
                }), 128))
              ]))
        ])
      ]),
      _: 1
    }, 8, ["expectedNetwork"])
  ]))
}