<template>
  <div>
    <div class="text-xl font-bold">ストーリー</div>
    <div class="pb-2"></div>
    <div>ルーは野心的な若いお茶職人です。</div>
    <div class="pb-1"></div>
    <div>
      ルーの一族は、ウサギの人生経験によって味が変わる「人生のお茶」を作る特別な力を受け継いでいます。
    </div>
    <div class="pb-1"></div>
    <div>
      このパワーは魔法と同じだと言う人が多いかもしれませんが、そうではありません。お茶を作る人は、お茶を作るためにたくさんの物事や感情を経験し、人生の知恵を手に入れなければならないのです。
    </div>
    <div class="pb-1"></div>
    <div>
      ルーの家では、このような生活の知恵を、誰かに教わるのではなく、自分で学ぶことが必要だと考えています。だから、お茶を作る人は皆家族と離れて旅に出る必要があります。
    </div>
    <div class="pb-1"></div>
    <div>
      ルーは町から町へと旅をして、人々と出会い、感情や人間関係を経験します。ルーは、甘い味のする愛や友情、幸せ、共感などさまざまな経験をしますが、時には、悲しみや怒りなど苦い味のする感情も経験します。
    </div>
    <div class="pb-1"></div>
    <div>
      さまざまな感情や体験に出会うことで、ルーは、そのような感情を収集することができます。
    </div>
    <div class="pb-1"></div>
    <div>野心に満ちた若きお茶職人が「人生のお茶」を作るための物語です。</div>
  </div>
</template>
