<template>
  <div>
    <div class="text-xl font-bold">Watch the pilot animation</div>
  </div>
  <div class="h-1.5"></div>
  <iframe
    src="https://player.vimeo.com/video/681693872?h=74ab7451a6&title=0&byline=0&portrait=0"
    width="100%"
    height="400"
    frameborder="0"
    allow="autoplay; fullscreen; picture-in-picture"
    allowfullscreen
    class="video"
  ></iframe>
</template>

<style>
@media screen and (max-width: 767px) {
  .video {
    height: 200px;
  }
}
</style>
