import languages from "./languages";

const lang = {
  menu: {
    connect: "Connect",
    nometamask: "No Metamask",
  },
  header: {
    lu: "Laidback Lu",
    pictureBook: "Picture Book",
    shop: "Shop",
    home: "Home",
  },
  footer: {
    home: "Home",
    termofservice: "　",
    notation: " ",
    privacy: " ",
  },
  mint: {
    switchNetwork: "Please switch the network.",
    switchNetworkButton: "Switch Network.",
    connectMetamask: "Please connect with Metamask.",
    mint: "Mint",
    mintAlertTitle: "Remarks: The Shop page shows a list of minted NFTs.",
    mintAlertBody: "When you press the light blue Mint button and complete the minting process, a new NFT will be added to the Shop page. You can see the design of the NFT that you have purchased after you complete the minting process.",
  },
  message: {
    notFoundMessage: "Page not found.",
    touchToUpdate: "Click images to regenerate.",
    processing: "Processing...",
    resources: "Resources",
  },
  languages,
};

export default lang;
