<template>
  <div>
    <div class="text-left text-xl font-bold">クリエイター Taka Kato</div>
    <div class="pb-2"></div>
    <div class="text-left text-lg">
      文房具のキャラクターデザイナーとして活躍した後に独立。その後イラストレーター、子供の絵本の作者、そしてアニメーション作品のデザイナー、監督として活動中。
    </div>
    <div class="pb-5"></div>
    <div class="text-left text-lg">
      「Doggy Bones in Twincle Wincle Somewhere Land」
    </div>
    <div class="text-left text-lg">
      2009年東京アニメアワード受賞。オリジナル作品。監督、脚本、デザイン
    </div>
    <div class="pb-5"></div>
    <div class="text-left text-lg">
      「Rocco」（30話x3分：現在Disney+にて放送中）監督・デザイン
    </div>
    <div class="text-left text-lg">／2018年 Baby Network Ltd</div>
    <div class="pb-5"></div>
    <div class="text-left text-lg">
      「くつだる。」（48話x7分）監督／NHK E テレにて放送。
    </div>
    <div class="text-left text-lg">
      その他子ども向けTV番組内アニメーション制作（監督・デザイン）作品多数
    </div>
    <div class="text-left text-lg">／NHK E テレ／フジテレビKIDS</div>
    <div class="pb-5"></div>
    <div class="text-left text-lg">
      「ユメミルアニメOn ちゃん」（52話 x 3分）監督・背景美術
    </div>
    <div class="text-left text-lg">／北海道テレビ放送</div>
    <div class="pb-5"></div>
    <div class="text-left text-lg">
      著書：絵本「ポケットの中のビビビ」「しっぽの国のビビビ」文溪堂
    </div>
    <div class="text-left text-lg">
      演劇宣伝美術：演劇集団キャラメルボックス、劇団しゅうくりー夢、他
    </div>
    <div class="pb-5"></div>
    <div class="pb-5"></div>
    <div class="items3">
      <div class="item">
        <div class="item-img">
          <img src="/images/DB.jpg" alt="" />
        </div>
        <div class="item-body">
          <div class="pt-1"></div>
          <div class="text-sm">
            Doggy Bones in Twincle Wincle Somewhere Land
          </div>
          <div class="text-sm" v-if="false">
            2009年東京アニメアワード受賞した加藤タカのオリジナル作品
          </div>
        </div>
      </div>
      <!-- /.item -->
      <div class="item">
        <div class="item-img">
          <img src="/images/Rocco.jpg" alt="" />
        </div>
        <div class="item-body">
          <div class="pt-1"></div>
          <div class="text-sm">Rocco</div>
          <div class="text-sm">Baby Network Ltd</div>
        </div>
      </div>
      <!-- /.item -->
      <div class="item">
        <div class="item-img">
          <img src="/images/Vivivi_01R.jpg" alt="" />
        </div>
        <div class="item-body">
          <div class="pt-1"></div>
          <div class="text-sm">ポケットの中のビビビ</div>
          <div class="text-sm">しっぽの国のビビビ</div>
        </div>
      </div>
      <!-- /.item -->
      <div class="item" v-if="false">
        <div class="item-body">
          <div class="pt-1"></div>
          <div class="text-sm">ころころ2</div>
          <div class="text-sm">NHK E テレにて放送。</div>
          <div class="text-sm">製作業務の一部を受託。</div>
        </div>
      </div>
      <!-- /.item -->
      <div class="item" v-if="false">
        <div class="item-body">
          <div class="pt-1"></div>
          <div class="text-sm">オンチャン</div>
          <div class="text-sm">北海道放送にて放送。</div>
          <div class="text-sm">監督・背景デザインを担当。</div>
        </div>
      </div>
      <!-- /.item -->
      <div class="item" v-if="false">
        <div class="item-body">
          <div class="pt-1"></div>
          <div class="text-sm">くつだる（48話x7分）</div>
          <div class="text-sm">NHK E テレにて放送。</div>
          <div class="text-sm">製作業務の一部を受託。</div>
        </div>
      </div>
      <!-- /.item -->
    </div>
    <!-- /.items3 -->
  </div>
</template>

<style>
.items3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px; /*縦・横で同じ余白*/
}

.items3 .item {
  width: 30%;
}

@media screen and (max-width: 767px) {
  .items3 {
    flex-direction: column;
  }
  .items3 .item {
    width: 100%;
  }
}
</style>
