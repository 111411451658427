
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { switchNetwork } from "@/utils/MetaMask";
import Connect from "@/components/TokenGates/Connect.vue";

export default defineComponent({
  props: ["expectedNetwork"],
  components: {
    Connect,
  },
  setup(props) {
    const store = useStore();
    const networkGate = computed(() => {
      if (!store.state.account) {
        return "noAccount";
      }
      if (store.state.chainId != props.expectedNetwork) {
        return "invalidNetwork";
      }
      return "valid";
    });
    const switchToValidNetwork = async () => {
      await switchNetwork(props.expectedNetwork);
    };
    return { networkGate, switchToValidNetwork };
  },
});
