<template>
  <div>
    <div class="text-xl font-bold">著作権その他本作品の取り扱いについて</div>
    <div class="pb-4"></div>
    <div class="text-lg">
      1）このサービスで提供される作品及び当該作品から展開される作品（それらのNFT画像や特典を含みます、以下総称する場合は「本作品」といいます）は、加藤タカによる著作物であり、著作権を含む知的財産権を有しています。
    </div>
    <div class="pb-4"></div>
    <div class="text-lg">
      2）本作品に関する購入者は、サービスの内容に応じた所有権又はサービスに基づくライセンスを取得する場合がありますが、著作権は譲渡されません。
    </div>
    <div class="pb-4"></div>
    <div class="text-lg">3）改変、商用利用の禁止</div>
    <div class="text-lg">
      ・本作品の一部または全ての画像、デザイン、音楽、動画等を許諾なく改変したり、商用利用することを禁止します。
    </div>
    <div class="pb-4"></div>
    <div class="text-lg">4）NFT画像ファイルの保存と利用</div>
    <div class="text-lg">
      ・NFT画像ファイルは、購入者に限り自身のパソコンやスマートフォン等へダウンロードし保存することを許可します。また、SNS等での自身のプロフィールでの使用やデジタルデバイスによる作品展示をすることを許諾します。
    </div>
    <div class="pb-4"></div>
    <div class="text-lg">
      詳細は<router-link to="/termsofservice" class="underline"
        >利用規約</router-link
      >をご確認ください。
    </div>
  </div>
</template>
