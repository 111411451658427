<template>
  <div>
    <div class="text-left text-xl font-bold">Creator Taka Kato</div>
    <div class="pb-2"></div>
    <div class="text-left text-lg">
      Based in Tokyo. After working as character designer for supplies, becomes
      independent. Works as an illustrator, creates children’s books, and design
      & directs children’s animation programs.
    </div>
    <div class="pb-5"></div>
    <div class="items3">
      <div class="item">
        <div class="item-img">
          <img src="/images/DB.jpg" alt="" />
        </div>
        <div class="item-body">
          <div class="pt-1"></div>
          <div class="text-sm">
            “Doggy Bones in Twinkle Winkle Somewhere Lans.” （Won the 2009Tokyo
            Anime Award Corporate Award）. Original
          </div>
        </div>
      </div>
      <!-- /.item -->
      <div class="item">
        <div class="item-img">
          <img src="/images/Rocco.jpg" alt="" />
        </div>
        <div class="item-body">
          <div class="pt-1"></div>
          <div class="text-sm">
            “Rocco” Baby TV (now on air) 30 × 3min Copyright ©︎2018Baby Network
            Limited. All rights reserved
          </div>
        </div>
      </div>
      <!-- /.item -->
      <div class="item">
        <div class="item-img">
          <img src="/images/Vivivi_01R.jpg" alt="" />
        </div>
        <div class="item-body">
          <div class="pt-1"></div>
          <div class="text-sm">
            Books “Vivivi in my pocket” “Vivivi in the Bisquetia-land” Published
            by Bunkeido
          </div>
        </div>
      </div>
      <!-- /.item -->
      <div class="item" v-if="false">
        <div class="item-body">
          <div class="pt-1"></div>
          <div class="text-sm">
            “Kutudaru” (Direction) NHK Educational 48 × 7min ©︎Masafumi
            Goto/KODANSHA/EGG
          </div>
        </div>
      </div>
      <!-- /.item -->
      <div class="item" v-if="false">
        <div class="item-body">
          <div class="pt-1"></div>
          <div class="text-sm">
            Korokorol2 × 1min (now on air) ©︎NHK Educational
          </div>
        </div>
      </div>
      <!-- /.item -->
      <div class="item" v-if="false">
        <div class="item-body">
          <div class="pt-1"></div>
          <div class="text-sm">
            “On chan” (Direction、Environment design). Hokkaido Television
            Broadcasting 52 × 3min ©︎HTB
          </div>
        </div>
      </div>
      <!-- /.item -->
    </div>
    <!-- /.items3 -->
  </div>
</template>

<style>
.items3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px; /*縦・横で同じ余白*/
}

.items3 .item {
  width: 30%;
}

@media screen and (max-width: 767px) {
  .items3 {
    flex-direction: column;
  }
  .items3 .item {
    width: 100%;
  }
}
</style>
