<template>
  <div class="items2">
    <div class="item-text">
      <div class="text-left text-xl font-bold">
        ビジョン: WEB3ベースのクリエイターとユーザーを繋ぐ⺠主的なビジネスモデル
      </div>
      <div class="pb-2"></div>
      <div class="text-left text-lg">
        Web3の技術によって、クリエイターと技術者がユーザーと直接ビジネスができるようになりました。そしてフルオンチェーンNFTはユーザからクリエイターへの信頼を得るための最良の方法です。
      </div>
      <div class="h-6"></div>
      <div class="text-left text-xl font-bold">
        『へろへろうさぎのルー』がフルオンチェーンNFTに登場!
      </div>
      <div class="pb-2"></div>
      <div class="text-left text-lg">
        『へろへろうさぎのルー』のイラストが革新的なフルオンチェーンNFTとして登場。
        パブリックセールスは2023年5月21日（日）日本時間の午前6時からです。
      </div>
    </div>

    <div class="item-png">
      <img src="/images/photo1.png" />
    </div>
  </div>
</template>

<style>
.items2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px; /*縦・横で同じ余白*/
}

.items2 .item-text {
  width: 60%;
}

.items2 .item-png {
  width: 25%;
}
@media screen and (max-width: 767px) {
  .items2 {
    flex-direction: column;
  }
  .items2 .item-text {
    width: 100%;
  }

  .items2 .item-png {
    width: 100%;
  }
}
</style>
