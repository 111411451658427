import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "mb-2" }
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Connect = _resolveComponent("Connect")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.networkGate == 'invalidNetwork')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("mint.switchNetwork")), 1),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.switchToValidNetwork && _ctx.switchToValidNetwork(...args))),
            class: "mb-2 inline-block rounded bg-green-600 px-6 py-2.5 leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg"
          }, _toDisplayString(_ctx.$t("mint.switchNetworkButton")), 1)
        ]))
      : (_ctx.networkGate == 'noAccount')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, [
              _createTextVNode(_toDisplayString(_ctx.$t("mint.connectMetamask")) + " ", 1),
              _createVNode(_component_Connect)
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "default")
          ]))
  ]))
}