import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Layout from "@/components/Layout.vue";
import Blank from "@/components/Blank.vue";
import NotFound from "@/components/NotFound.vue";

import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import TermsOfService from "@/views/TermsOfService.vue";
import Privacy from "@/views/Privacy.vue";
import Notation from "@/views/Notation.vue";

import Mint from "@/views/Mint.vue";

const routeChildren: Array<RouteRecordRaw> = [
  {
    path: "",
    component: Home,
  },
  {
    path: "about",
    component: About,
  },
  {
    path: "termsofservice",
    component: TermsOfService,
  },
  {
    path: "privacy",
    component: Privacy,
  },
  {
    path: "notation",
    component: Notation,
  },
  {
    path: "mint",
    component: Mint,
  },
  
];

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "/:lang",
        component: Blank,
        children: routeChildren,
      },
      {
        path: "",
        component: Blank,
        children: routeChildren,
      },
    ],
  },
  {
    path: "/:page(.*)",
    name: "NotFoundPage",
    component: NotFound,
  },
];

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        top: 0,
      };
    }
  },

  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
