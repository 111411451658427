<template>
  <div class="home">
    <div class="mx-auto w-3/4 text-left">
      <div class="pb-8"></div>
      <div class="text-xl font-bold">利用規約</div>
      <div class="pb-7"></div>

      <div class="text-base">
        <div>
          この利用規約（以下「本規約」といいます。）は、お客様（以下、「ユーザー」といいます。）が、株式会社CultureConnect（以下「当社」といいます。）によるNFTの販売サービス（以下「本サービス」といいます。）をご利用頂く際に、遵守すべき事項及び当社とユーザー間の権利義務関係を定めるものです。ユーザーは、本規約及び当社が別途定めるプライバシーポリシーに同意した上で本サービスを利用するものとします。
        </div>
        <div class="pb-10"></div>
        <div class="text-lg font-bold">第1条（目的及び本サービスの内容等）</div>
        <div class="pb-4"></div>
        <div class="ml-5">
          <ol class="list-decimal">
            <li>
              本規約は、本サービスの利用に関する条件及び当社とユーザーの間の権利義務関係を定めることを目的としています。
            </li>
            <div class="h-2"></div>
            <li>
              本サービスは、当社が提供するウェブサイト（以下「本ウェブサイト」といい、同サイトよりリンクするウェブサイトを含みます。）を通じて、当社から、ユーザーに対し、NFT（Non-Fungible
              Tokenの略称であり、ブロックチェーン技術を用いてブロックチェーン上で発行される非代替性トークンをいいます。以下同様です。）を販売するサービスです（以下、本サービス上で販売されるNFTを「本NFT」といいます。）。
            </li>
            <div class="h-2"></div>
            <li>
              未成年者であるユーザーは、親権者その他法定代理人の同意を得た上で、本サービスをご利用ください。親権者その他法定代理人の同意を得ていないにもかかわらず、同意があり、または成年であると偽って本サービスを利用した場合、その他行為能力者であることを信じさせるために詐術を用いた場合、本サービスに関する一切の法律行為を取り消すことは出来ません。
            </li>
          </ol>
        </div>

        <div class="pb-10"></div>

        <div class="text-lg font-bold">第2条（ウォレットの利用等）</div>
        <div class="pb-4"></div>

        <div class="ml-5">
          <ol class="list-decimal">
            <li>
              本サービスを利用して本NFTを購入するためには、MetaMaskその他の暗号資産管理ウォレットをユーザーが自らの費用と責任により用意する必要があります。
            </li>
            <div class="h-2"></div>
            <li>
              ユーザーは、本サービスの利用にあたり、自身のウォレットの秘密鍵またはシードフレーズに関する情報その他本サービスの利用に伴い使用する一切の情報（以下「ウォレット情報等」といいます。）を自らの費用と責任において管理し、第三者による不正使用を防止するために必要な措置を講じるものとし、自身のウォレット情報等を使用して自己または第三者が行った行為について、当社は一切の責任を負わないものとします。
            </li>
            <div class="h-2"></div>
            <li>
              当社は、第三者がウォレット情報等を使用してなされた行為を、当該ウォレット情報等を管理すべきユーザーによる行為とみなすことができるものとします。
            </li>
          </ol>
        </div>

        <div class="pb-10"></div>

        <div class="text-lg font-bold">第3条（本NFTの購入・転売等）</div>
        <div class="pb-4"></div>

        <div class="ml-5">
          <ol class="list-decimal">
            <li>
              ユーザーは、当社所定の方法により本NFTの購入を申し込むものとし、本ウェブサイト上に表示された本NFTの価格に相当するETHその他の暗号資産を当社が定めるアドレスに送付し、当社が当該暗号資産を現に受領した時点で、本NFTに関する売買契約（以下「本契約」といい、本契約におけるユーザーを「購入者」とします。）が成立するものとします。
            </li>
            <div class="h-2"></div>
            <li>
              本NFTの購入に際して必要となるガス代（ブロックチェーンネットワークを利用する際に支払うETHその他の暗号資産建ての手数料をいいます。以下同じです。）は、ユーザーが負担するものとします。トランザクションエラーその他のブロックチェーンネットワークの不具合により、当社がETHその他の暗号資産を受領できなかった場合にユーザーが支出したガス代についても同様とします。
            </li>
            <div class="h-2"></div>
            <li>
              当社は、購入者に対し、購入者のウォレットアドレスに本NFTを送付する（以下「本NFTの引渡し」といいます。）ことをもって、当社による本契約の履行が完了するものとします。
            </li>
            <div class="h-2"></div>
            <li>
              購入者は、本NFTの引渡し後は、法令上認められる場合を除き、いかなる理由によっても、本契約の解除、取消し、またはキャンセルをすることはできず、当社は、返品及び返金には応じないものとします。
            </li>
            <div class="h-2"></div>
            <li>
              購入者は、NFT取引市場であるOpenSeaその他の第三者が提供するNFTマーケットプレイス（以下「NFTマーケットプレイス」といいます。）において本NFTの転売ができます。本NFTがNFTマーケットプレイスにおいて転売され、第三者により購入された場合、当社は、NFTマーケットプレイスを提供する第三者の定める規則等に基づき、当該転売された本NFTの売買価格の一部を受け取る場合があります。購入者は、本NFTを転売する場合、自己の費用と責任によりNFTマーケットプレイスを提供する第三者の定める規則等に従ってこれを利用するものとし、当社は、当社に故意又は重大な過失があるときを除き、NFTマーケットプレイスの利用について一切の責任を負いません。
            </li>
            <div class="h-2"></div>
            <li>
              購入者が、本NFTについて行うことできる行為は以下のとおりです。
              <div class="ml-7">
                <ol style="list-style-type: lower-latin">
                  <div class="h-1"></div>
                  <li>
                    NFTで使われている画像データのコピーを購入者の所有する端末に保存し、閲覧すること
                  </li>
                  <div class="h-1"></div>
                  <li>
                    本NFTで使われている画像データのコピーを非営利目的でSNSへ投稿し、又はアイコンとして使用をすること
                  </li>
                  <div class="h-1"></div>
                  <li>NFTマーケットプレイスにおいて、本NFTを転売すること</li>
                  <div class="h-1"></div>
                  <li>その他、当社が明示して承諾する行為</li>
                </ol>
              </div>
            </li>

            <div class="h-2"></div>
            <li>
              本サービスのシステムエラー又はバグの利用その他不正な方法により本NFTの購入が行われたと当社が判断した場合、当社は、当該購入を行った購入者に対し、本サービスその他の本NFTに関連して当社が提供するサービスの全部又は一部の利用を停止し、制限し、本NFTの売買契約その他当社と購入者との間の契約を解除することができるものとします。また、当社は、当該措置により購入者に損害が生じた場合であっても、当社に故意又は重過失ある場合を除き、責任を負わないものとします。
            </li>
          </ol>
        </div>

        <div class="pb-10"></div>

        <div class="text-lg font-bold">第4条（料金及び費用）</div>
        <div class="pb-4"></div>

        <div class="ml-5">
          <ol class="list-decimal">
            <li>
              本NFTの料金は、本ウェブサイト上における本NFTの購入ページに表示され
              ます。
            </li>
            <div class="h-2"></div>
            <li>
              本NFTの購入には、本NFTの料金の他に、ガス代が必要となります。
            </li>
            <div class="h-2"></div>
            <li>
              本NFTの購入及び本サービスの利用に伴い、税金や附帯費用が発生する場合に
              はユーザーがこれらを負担するものとします。
            </li>
            <div class="h-2"></div>
            <li>
              本サービスの利用にあたって必要な端末の購入、導入または維持に係る費用及
              び本サービスの利用の際に発生する一切の通信費用はユーザーがこれを負担するものとします。
            </li>
          </ol>
        </div>

        <div class="pb-10"></div>

        <div class="text-lg font-bold">第5条（特典の取得）</div>
        <div class="pb-4"></div>

        <div class="ml-5">
          <ol>
            <li>
              当社は、当社所定の条件を満たしたお客様に特典を提供することがあります。特典の取扱い及び品質等については、本規約第3条5項及び6項、第6条、第10条が準用されます。また、特典の提供は本サービスの一部であり、その他本規約の規定が適用されます。特典の提供方法は、当社の告知方法によることとします。
            </li>
          </ol>
        </div>

        <div class="pb-10"></div>

        <div class="text-lg font-bold">第6条（知的財産権等）</div>
        <div class="pb-4"></div>

        <div class="ml-5">
          <ol class="list-decimal">
            <li>
              本NFTに係る創作物及び本サービスを通じて提供されまたは本サービスを構成
              する文章、画像、プログラムその他一切の情報（以下、総称して単に「本サービスのコンテンツ」といいます。）に係る知的財産権（著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）をいいます。以下同じです。）は、当社または当該権利を有する第三者に帰属しています。当社による本サービスの利用を許す行為及び本NFTの引渡しは、これらの権利の移転とは解釈されないものとします。
            </li>
            <div class="h-2"></div>
            <li>
              ユーザーは、本規約によって許容されている場合を除いて、本NFTの複製、改変、翻案その他の二次創作行為等を行ってはならないものとします。
            </li>
          </ol>
        </div>

        <div class="pb-10"></div>

        <div class="text-lg font-bold">第7条（禁止事項）</div>
        <div class="pb-4"></div>

        <div class="ml-5">
          <ol class="list-decimal">
            <li>
              ユーザーは、次の各号に定める行為またはそのおそれのある行為を行ってはな
              らないものとします。

              <div class="ml-7">
                <ol style="list-style-type: lower-latin">
                  <div class="h-1"></div>
                  <li>法令、公序良俗または本規約に違反する行為</li>
                  <div class="h-1"></div>
                  <li>
                    犯罪行為若しくは犯罪に結びつく行為またはこれらの行為を援助若しくは助⻑する行為
                  </li>
                  <div class="h-1"></div>
                  <li>当社または第三者の知的財産権を侵害する行為</li>
                  <div class="h-1"></div>
                  <li>
                    本サービスまたは本サービスのコンテンツを改変若しくは毀損する行為
                  </li>
                  <div class="h-1"></div>
                  <li>
                    本サービスまたは本サービスのコンテンツを逆アセンブル、逆コンパイル若しくはリバースエンジニアリングする行為
                  </li>
                  <div class="h-1"></div>
                  <li>
                    本サービスのコンテンツを不正な方法で取得しまたは不正に転載する行為
                  </li>
                  <div class="h-1"></div>
                  <li>
                    当社若しくは第三者を不当に差別若しくは誹謗中傷し、または当社若しくは本サービスの名誉若しくは信用を毀損する行為
                  </li>
                  <div class="h-1"></div>
                  <li>当社または第三者に対して損害を与える行為</li>
                  <div class="h-1"></div>
                  <li>本サービスの運営を妨害する行為</li>
                  <div class="h-1"></div>
                  <li>
                    法令等により本NFTの購入が禁止、制限その他規制されている国または地域の国⺠、住人、居住者または滞在者が本NFTを購入する行為
                  </li>
                  <div class="h-1"></div>
                  <li>
                    当社がユーザーの情報を取得する際に、虚偽または故意に誤った情報を申告すること
                  </li>
                  <div class="h-1"></div>
                  <li>その他当社が不適切であると判断した行為</li>
                </ol>
              </div>
            </li>
            <div class="h-2"></div>
            <li>
              当社は、ユーザーが前項に違反したと判断した場合には、当該ユーザーに対し、本サービスの全部または一部の利用を停止し、制限し、本契約を解除し、その他当社が必要と判断した措置をとることができるものとします。
            </li>
            <div class="h-2"></div>
            <li>
              ユーザーが第1項に違反した場合、当社は、当該ユーザーに対し、当該違反により当社が被った一切の損害の賠償を請求できるものとします。
            </li>
          </ol>
        </div>

        <div class="pb-10"></div>

        <div class="text-l font-bold">
          第8条（本サービスの変更、停止、中止、終了）
        </div>
        <div class="pb-4"></div>

        <div class="ml-5">
          <ol class="list-decimal">
            <li>
              当社は、当社が必要と判断した場合には、本サービスに新たな機能を追加し、本サービスの内容または名称を変更し、その他本サービスに一切の変更を加えることができるものとします。
            </li>
            <div class="h-2"></div>
            <li>
              当社は、次の各号に定めるいずれかの事由が生じた場合、本サービスの全部または
              一部の提供を停止し、中止し、または終了することができるものとします。
              <div class="ml-7">
                <ol style="list-style-type: lower-latin">
                  <div class="h-1"></div>
                  <li>
                    当社が、定期的または緊急に、本サービス提供のためのコンピュータシステムの保守・点検を行う場合
                  </li>
                  <div class="h-1"></div>
                  <li>
                    火災、停電、天災地変等の非常事態により本サービスの運営が不能となった場合
                  </li>
                  <div class="h-1"></div>
                  <li>
                    地震、津波、台風、雷、大雨、洪水等の自然災害、火災、停電その他の不慮の事故、戦争、争議、動乱、暴動、騒乱、労働争議、伝染病その他の疫病及び感染症並びにそれらに伴う社会閉鎖等の不可抗力により、本サービスの運営が不能となった場合
                  </li>
                  <div class="h-1"></div>
                  <li>
                    本サービスの提供のためのコンピュータシステムの不良及び第三者からの不正アクセス、コンピュータウイルスの感染等により本サービスを提供できない場合
                  </li>
                  <div class="h-1"></div>
                  <li>
                    法律、法令等に基づく措置により本サービスが提供できない場合
                  </li>
                  <div class="h-1"></div>
                  <li>
                    当社が、事前に電子メール、ディスコード内での通知その他の手段により、合理的な範囲で周知した場合
                  </li>
                  <div class="h-1"></div>
                  <li>その他、当社がやむを得ないと判断した場合</li>
                </ol>
              </div>
            </li>
            <div class="h-2"></div>
            <li>
              当社は、前項により本サービスの全部または一部の提供を停止し、中止し、または終了する場合には、合理的な範囲で、事前に本サービスまたは当社の別途指定するウェブサイトに掲載する等の手段により、ユーザーに対し、その旨を通知するものとします。ただし、緊急の場合には、この限りではありません。
            </li>
          </ol>
        </div>

        <div class="pb-10"></div>

        <div class="text-lg font-bold">第9条（反社会的勢力等の排除）</div>
        <div class="pb-4"></div>

        <div class="ml-5">
          <ol class="list-decimal">
            <li>
              ユーザーは、自身（ユーザーが法人の場合は、代表者、役員又は実質的に経営を支配する者）が暴力団、暴力団員、暴力団関係企業、総会屋、社会運動標ぼうゴロ、特殊知能暴力集団、若しくはこれらに準ずる者又はその構成員等の反社会的勢力等（以下「反社会的勢力等」といいます。）に該当しないことを表明し、かつ将来にわたっても該当しないことを保証するものとします。
            </li>
            <div class="h-2"></div>
            <li>
              ユーザーは、自らまたは第三者をして次の各号に定める行為を行わないことを確約するものとします。
              <div class="ml-7">
                <ol style="list-style-type: lower-latin">
                  <div class="h-1"></div>
                  <li>暴力的な要求行為</li>
                  <div class="h-1"></div>
                  <li>法的な責任を超えた不当な要求行為</li>
                  <div class="h-1"></div>
                  <li>脅迫的な言動をし、または暴力を用いる行為</li>
                  <div class="h-1"></div>
                  <li>
                    風説の流布、偽計若しくは威力を用いて当社の信用を毀損し、または当社の業務を妨害する行為
                  </li>
                  <div class="h-1"></div>
                  <li>
                    方法及び態様の如何を問わず反社会的勢力等と関与する行為
                  </li>
                </ol>
              </div>
            </li>
            <div class="h-2"></div>
            <li>
              ユーザーは、随時、当社が行う、前項各号に掲げる者が反社会的勢力でないことに関する調査に合理的な範囲で協力し、当社から合理的理由に基づき求められた資料等を提出するものとします。
            </li>
            <div class="h-2"></div>
            <li>
              当社は、ユーザーが本条の規定に違反していると判断した場合、何らの催告をすることなく、当社とユーザー間の契約を解除及び損害の賠償を請求することができます。これによりユーザーに生じたいかなる損害について、当社は一切の責任を負いません。
            </li>
          </ol>
        </div>

        <div class="pb-10"></div>

        <div class="text-lg font-bold">第10条（免責）</div>
        <div class="pb-4"></div>

        <div class="ml-5">
          <ol class="list-decimal">
            <li>
              当社は、本サービスにより提供されるコンテンツや情報の完全性、確実性、妥当性、有用性、最新性、適法性またはユーザーの利用目的及び環境との適合性を保証しません。当社は、ユーザーが本サービス、当該コンテンツ及び当該情報を利用し、または利用できなかったことによって生じる損害について、当社に故意または重過失ある場合を除
              き、責任を負いません。
            </li>
            <div class="h-2"></div>
            <li>
              当社は、本サービスの利用に起因して生じたユーザー間またはユーザーと第三者との間の紛争に関し、当社に故意または重過失ある場合を除き、責任を負いません。
            </li>
            <div class="h-2"></div>
            <li>
              当社は、本サービスに対する不正アクセス、コンピューターウイルスの侵入等、第三者の行為に起因してユーザーに生じる損害について、当社に故意または重過失ある場合を除き、責任を負いません。
            </li>
            <div class="h-2"></div>
            <li>
              当社は、電気通信事業者、電気事業者その他の事業者に起因してユーザーに生じる損害について、当社に故意または重過失ある場合を除き、責任を負いません。
            </li>
            <div class="h-2"></div>
            <li>
              当社は、本条第1項による本サービスの変更または本条第2項による本サービスの提供の全部若しくは一部の停止、中止若しくは終了に起因してユーザーに発生した一切の損害（情報等の消失に起因する損害を含みますが、これらに限りません。）について、当社に故意または重過失がある場合を除き、責任を負わないものとします。
            </li>
            <div class="h-2"></div>
            <li>
              当社は、本NFTが投機的又は金銭的な価値を有すること、本NFTの価値が維持、上昇、減少することについて保証せず、暗号資産又は本NFTの市場価格の変動に起因する損害について、一切の責任を負いません。
            </li>
          </ol>
        </div>

        <div class="pb-10"></div>

        <div class="text-lg font-bold">第11条（損害賠償責任）</div>
        <div class="pb-4"></div>

        <div class="ml-5">
          <ol>
            <li>
              当社のユーザーに対する損害賠償責任の範囲は、ユーザーが直接かつ現実に被った
              通常の損害とし、かつ、当該ユーザーから受領した本NFTの購入代金額を超えないものとします。ただし、当社に故意または重過失がある場合には、この限りではありません。
            </li>
          </ol>
        </div>

        <div class="pb-10"></div>

        <div class="text-lg font-bold">第12条（権利義務の譲渡禁止）</div>
        <div class="pb-4"></div>

        <div class="ml-5">
          <ol>
            <li>
              ユーザーは、本規約に基づくNFTマーケットプレイスにおける転売を除いて、当社の書面による事前承諾なしに、本契約上の地位または本契約若しくは本規約に基づく権利義務の全部または一部を、第三者に対し、譲渡し、移転し、担保設定し、その他の処分をしてはならないものとします。
            </li>
            <div class="h-2"></div>
          </ol>
        </div>

        <div class="pb-10"></div>
        <div class="text-lg font-bold">第13条（本規約の改訂）</div>
        <div class="pb-4"></div>

        <div class="ml-5">
          <ol class="list-decimal">
            <li>
              当社は、以下の場合、本規約をユーザーの承諾なく変更できるものとします。当該変更の結果、個々のユーザーに不利益または損害が生じた場合であっても、当社は責任を負いません。
              <div class="ml-7">
                <ol style="list-style-type: lower-latin">
                  <div class="h-1"></div>
                  <li>本規約の変更が、利用者の一般の利益に適合する場合。</li>
                  <div class="h-1"></div>
                  <li>
                    本規約の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものである場合。
                  </li>
                </ol>
              </div>
            </li>
            <div class="h-2"></div>
            <li>
              本規約を変更した場合、当社は、変更があった旨、変更の内容及び変更の効力の発生時期を、効力発生時期の2週間前までに、電子メール、ディスコード内での通知その他の適切な方法でユーザーに通知するとともに、変更後の本規約を当社が定めるウェブサイトその他の適切な場所に掲載します。
            </li>
            <div class="h-2"></div>
            <li>
              本規約の変更に同意しないユーザーは、前項に定める変更の効力発生日の前日までに、当社が別途定める手続により当社に通知することで、本契約を解約することができるものとします。
            </li>
            <div class="h-2"></div>
            <li>
              ユーザーは、本規約の変更の効力の発生後も本サービスの利用を継続することで、かかる変更に同意したものとみなされます。
            </li>
          </ol>
        </div>

        <div class="pb-10"></div>

        <div class="text-lg font-bold">第14条（誠実協議）</div>
        <div class="pb-4"></div>

        <div class="ml-5">
          <ol>
            <li>
              当社及びユーザーは、本規約に関する解釈上の疑義、本規約に定めのない事項その他本サービスに関連する事項については、法令及び商慣習によるほか、信義誠実の精神に基づき協議を行い、解決を図るものとします。
            </li>
          </ol>
        </div>

        <div class="pb-10"></div>

        <div class="text-lg font-bold">第15条（準拠法及び裁判管轄）</div>
        <div class="pb-4"></div>

        <div class="ml-5">
          <ol class="list-decimal">
            <li>
              本規約は、日本法に準拠し、日本法に従って解釈されるものとします。
            </li>
            <div class="h-2"></div>
            <li>
              本規約または本契約に起因または関連して生じた訴訟その他の一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
            </li>
          </ol>
        </div>

        <div class="pb-10"></div>
      </div>
    </div>
  </div>
</template>
