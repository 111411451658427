<template>
  <div class="home">
    <div class="mx-auto w-3/4 text-left">
      <div class="pb-8"></div>
      <div class="text-xl font-bold">プライバシーポリシー</div>
      <div class="pb-7"></div>

      <div class="text-base">
        <div>
          株式会社 Culture
          Connect（以下「当社」といいます。）は、当社のサービス（以下「本サービス」といいます。）を利用する方（以下「利用者」といいます。）の個人情報の取扱いについて、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、全従業員に個人情報保護の重要性を認識させるとともにその取組みを徹底させることにより、個人情報の保護を推進します。
        </div>
        <div class="pb-10"></div>
        <div class="text-lg font-bold">第１条（個人情報）</div>
        <div class="pb-4"></div>

        <div>
          「個人情報」とは、個人情報の保護に関する法律（平成十五年法律第五十七号、以下「個人情報保護法」といいます。）にいう「個人情報」を指し、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別できるもの又は個人識別符号が含まれるものを指します。
        </div>

        <div class="pb-10"></div>

        <div class="text-lg font-bold">第2条（個人情報の取得と利用）</div>
        <div class="pb-4"></div>
        <div class="ml-5">
          <ol class="list-decimal">
            <li>
              当社は、以下の目的に必要な範囲で、利用者の個人情報を取得し、取得した情報を利用させていただきます。以下の目的の範囲を超えて個人情報を利用する場合には、事前に適切な方法で利用者からの同意を得るものとします。
              <div class="ml-7">
                <ol style="list-style-type: lower-latin">
                  <div class="h-1"></div>
                  <li>当社の本サービスを提供するため</li>
                  <div class="h-1"></div>
                  <li>
                    本サービスの内容を改良・改善し、又は新サービスを開発するため
                  </li>
                  <div class="h-1"></div>
                  <li>
                    本サービスの新機能、更新情報、キャンペーン等及び当社が提供する他のサービスのご案内（電子メール、チラシ、その他のダイレクトメールの送付を含む）のため
                  </li>
                  <div class="h-1"></div>
                  <li>
                    メンテナンス、重要なお知らせなど必要に応じたご連絡のため
                  </li>
                  <div class="h-1"></div>
                  <li>
                    本サービスに関する利用者からのご意見、お問い合わせ等に回答するため（本人確認を行うことを含む）
                  </li>
                  <div class="h-1"></div>
                  <li>本サービスの利用状況を利用者にご報告するため</li>
                  <div class="h-1"></div>
                  <li>
                    本サービスに関するアンケート・取材等のご協力依頼や各種イベントへのご参加をお願いし、又はその結果などをご報告するため
                  </li>
                  <div class="h-1"></div>
                  <li>
                    本サービスの利用履歴等を調査・分析し、その結果を本サービスの改良・開発や広告の配信に利用するため
                  </li>
                  <div class="h-1"></div>
                  <li>
                    利用者の承諾・申し込みに基づく、当社主催イベントの参加企業等への個人情報の提供
                  </li>
                  <div class="h-1"></div>
                  <li>
                    利用規約に違反した利用者や、不正・不当な目的で本サービスを利用しようとする利用者の特定をし、利用規約を実施ため
                  </li>
                </ol>
              </div>
            </li>
            <div class="h-6"></div>
            <li>
              当社は、前項の目的に必要な範囲で、以下の個人情報を取得することがあります。
              <div class="ml-7">
                <ol style="list-style-type: lower-latin">
                  <div class="h-1"></div>
                  <li>
                    本人確認・カスタマーサポートに伴う情報
                    <div class="h-1"></div>
                    当社による本人確認、カスタマーサポートのために、氏名、ユーザー名またはニックネーム、年齢または生年月日、住所、電話番号、メールアドレス、ユーザープロフィール（プロフィール画像、サービスのユーザー名またはニックネーム、性別、経歴、SNS上のプロフィールへのリンクその他お客様が同プロフィールに記載している情報）を取得します。
                  </li>
                  <div class="h-1"></div>
                  <li>
                    端末機器情報
                    <div class="h-1"></div>
                    利用者が利用される機器情報（OS、端末の個体識別情報、コンピュータ名等）、電話番号、国、およびユーザー名、もしくはメールアドレスなどを取得する場合がございます。これらの情報はより良いサービス提供のため、またご本人確認や正常なサービス提供を妨害する不正行為防止のために利用いたします。
                  </li>
                  <div class="h-1"></div>
                  <li>
                    支払情報
                    <div class="h-1"></div>
                    当社の本サービス内で使用される通貨、コンテンツ又はそれらを使用するためのラ
                    イセンスを当社から取得する場合、当社は決済処理に必要な支払情報を取得いたし
                    ます。
                  </li>
                  <div class="h-1"></div>
                  <li>
                    商品発送情報
                    <div class="h-1"></div>
                    利用者が当社の本サービスを経由して商品を取得する場合、当社は、住所、電話番号、
                    氏名等の商品の発送に必要な商品発送情報を取得します。第三者の販売代行会社がお客様の購入または発送を処理する場合は、利用者から販売代行会社に提供された商品発送情報を当社が取得する場合があります。
                  </li>
                  <div class="h-1"></div>
                  <li>
                    クッキー（Cookie）
                    <div class="h-1"></div>
                    利用者が利用された本サービスに対する訪問回数及び利用形態、利用者の規模等の
                    把握により、より良いサービスを提供するためにクッキーを利用いたします。利用者はクッキーの使用可否を選択できますが、クッキーの保存を拒む場合にはログインが必要なサービス等、本サービスの一部をご利用いただけない場合がございます。
                  </li>
                </ol>
              </div>
            </li>
          </ol>
        </div>
        <div class="pb-10"></div>

        <div class="text-lg font-bold">第３条 （個人情報の管理と保護）</div>
        <div class="pb-4"></div>

        <div>
          個人情報の管理は、厳重に行うこととし、次に掲げる場合を除き、利用者の同意がない限り、第三者に対しデータを開示・提供することはいたしません。また、安全性を考慮し、個人情報への不正アクセス、個人情報の紛失、破壊、改ざん及び漏えい等のリスクに対する予防並びに是正に関する対策を講じます。
        </div>
        <div class="ml-5">
          <div class="h-2"></div>
          <ol class="list-decimal">
            <li>
              人の生命、身体又は財産の保護のために必要がある場合であって、利用者の同意を得ることが困難である場合
            </li>
            <div class="h-2"></div>
            <li>
              公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、利用者の同意を得ることが困難である場合
            </li>
            <div class="h-2"></div>
            <li>
              国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用者の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
            </li>
            <div class="h-2"></div>
            <li>
              業務を円滑に遂行するため、利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託する場合
            </li>
            <div class="h-2"></div>
            <li>
              合併その他の事由による事業の承継に伴って個人情報が提供される場合
            </li>
            <div class="h-2"></div>
            <li>
              個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的及び当該個人情報の管理について責任を有する者の氏名又は名称について、あらかじめ利用者に通知し、又は利用者が容易に知り得る状態に置いた場合
            </li>
            <div class="h-2"></div>
            <li>その他法令で認められる場合</li>
          </ol>
        </div>

        <div class="pb-10"></div>

        <div class="text-lg font-bold">第４条（個人情報の取扱いの委託）</div>
        <div class="pb-4"></div>
        <div>
          当社は、利用目的の達成に必要な範囲内において、個人情報の取扱いの全部又は一部を委託する場合がございます。この場合、当社は、委託先としての適格性を十分審査するとともに、契約にあたって守秘義務に関する事項等を定め、委託先に対する必要かつ適切な監督を行います。
        </div>

        <div class="pb-10"></div>
        <div class="text-lg font-bold">第５条（個人情報の開示）</div>
        <div class="pb-4"></div>
        <div>
          当社は、利用者（本人に限る。本条において以下同じ）から個人情報の開示を求められ
          たときは、利用者に対し、遅滞なくこれを開示します。但し、開示することにより次のいずれかに該当する場合は、その全部又は一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。
        </div>
        <div class="ml-5">
          <ol class="list-decimal">
            <div class="h-2"></div>
            <li>
              利用者又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
            </li>
            <div class="h-2"></div>
            <li>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
            <div class="h-2"></div>
            <li>その他法令に違反することとなる場合</li>
          </ol>
        </div>

        <div class="pb-10"></div>

        <div class="text-lg font-bold">第６条（個人情報の訂正及び削除）</div>
        <div class="pb-4"></div>

        <div class="ml-5">
          <ol class="list-decimal">
            <li>
              当社の保有する個人情報が誤った情報である場合には、利用者の請求により、当社が定める手続きに従い個人情報の訂正又は削除を行います。
            </li>
            <div class="h-2"></div>
            <li>
              当社は、利用者から前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正又は削除を行い、これを利用者に通知します。
            </li>
          </ol>
        </div>

        <div class="pb-10"></div>
        <div class="text-lg font-bold">第７条 （個人情報の利用停止等）</div>
        <div class="pb-4"></div>
        <div>
          当社は、利用者から、以下の各号に定める場合に該当するとして、個人情報の利用の停止又は消去（以下「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、法令に従って、個人情報の利用停止等を行い、その旨利用者に通知します。但し、個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、利用者の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。
        </div>

        <div class="ml-5">
          <ol class="list-decimal">
            <div class="h-2"></div>
            <li>利用目的の範囲を超えて取り扱われている場合</li>
            <div class="h-2"></div>
            <li>不正の手段により取得された個人情報である場合</li>
            <div class="h-2"></div>
            <li>
              違法又は不当な行為を助⻑し、又は誘発するおそれがある方法により利用されている場合
            </li>
            <div class="h-2"></div>
            <li>当社が利用者の個人情報を利用する必要がなくなった場合</li>
            <div class="h-2"></div>
            <li>
              要配慮個人情報が含まれる個人情報の漏えい、滅失若しくは毀損（以下「漏えい等」といいます。）が発生し、又は発生したおそれがある場合
            </li>
            <div class="h-2"></div>
            <li>
              不正に利用されることにより財産的被害が生じるおそれがある個人情報の漏えい等が発生し、又は発生したおそれがある場合
            </li>
            <div class="h-2"></div>
            <li>
              不正の目的をもって行われたおそれがある個人情報の漏えい等が発生し、又は発生したおそれがある場合
            </li>
            <div class="h-2"></div>
            <li>
              個人情報にかかる本人の数が千人を超える漏えい等が発生し、又は発生したおそれがある場合
            </li>
            <div class="h-2"></div>
            <li>
              当該個人情報の取扱いにより利用者の権利又は正当な利益が害されるおそれがある場合
            </li>
          </ol>
        </div>

        <div class="pb-10"></div>

        <div class="text-lg font-bold">
          第８条（プライバシーポリシーの変更手続）
        </div>
        <div class="pb-4"></div>
        <div>
          当社は本ポリシーの内容を適宜見直し、変更することがあります。変更後のプライバシーポリシーは、法令又は当社所定の方法により、利用者に通知し、又は当社ウェブサイトに掲載したときから効力を生じるものとします。
        </div>
        <div class="pb-10"></div>

        <div class="text-lg font-bold">第９条（法令、規範の遵守）</div>
        <div class="pb-4"></div>
        <div>
          当社は、保有する個人情報に関して適用される法令、その他規範を遵守します。
        </div>
        <div class="pb-10"></div>

        <div class="text-lg font-bold">第１０条（苦情及び相談への対応）</div>
        <div class="pb-4"></div>
        <div>
          当社は、個人情報の取扱いに関する利用者からの苦情、相談を受け付け、適切かつ迅速に対応いたします。また、利用者からの当該個人情報の開示、訂正、追加、削除、利用又は提供の拒否などのご要望に対しても、迅速かつ適切に対応いたします。
        </div>
        <div class="pb-10"></div>

        <div class="text-lg font-bold">第１１条（安全管理措置）</div>
        <div class="pb-4"></div>
        <div>
          当社が利用者よりお預かりした個人情報は、個人情報ファイルへのアクセス制限の実施、アクセスログの記録及び外部からの不正アクセス防止のためのセキュリティ対策の実施等、組織的、物理的、人的、技術的施策を講じることで個人情報への不正な侵入、個人情報の紛失、破壊、改ざん、及び漏えい等を防止いたします。万一、利用者の個人情報の漏えい等の事故が発生した場合、当社は、個人情報保護法及び関連するガイドラインに則り、速やかに監督官庁への報告を行うとともに、当該監督官庁の指示に従い、類似事案の発生防止措置及び再発防止措置等の必要な対応を行います。
        </div>
        <div class="pb-10"></div>

        <div class="text-lg font-bold">
          第１２条（当社住所・代表者氏名・個人情報保護管理者）
        </div>
        <div class="pb-4"></div>
        <div>
          当社住所、代表者および個人情報保護管理者の氏名は以下のとおりです。
        </div>
        <div class="h-2"></div>
        <div>
          住所：京都府京都市中京区高倉通御池上る柊町581イーグルコート御所南903
        </div>
        <div class="h-1"></div>
        <div>代表者：風早完次</div>
        <div class="h-1"></div>
        <div>個人情報保護管理者：風早完次</div>

        <div class="pb-10"></div>

        <div class="text-lg font-bold">第１３条（お問い合わせ窓口）</div>
        <div class="pb-4"></div>
        <div>
          当社の個人情報の取扱に関するお問い合せは下記までご連絡ください。
        </div>
        <div class="h-2"></div>
        <div>株式会社 Culture Connect お客様対応窓口</div>
        <div class="h-1"></div>
        <div>〒 604-0826</div>
        <div class="h-1"></div>
        <div>
          【住所】京都府京都市中京区高倉通御池上る柊町581イーグルコート御所南903
        </div>
        <div class="h-1"></div>
        <div>Mail: info@cultureconnect.co.jp</div>
        <div class="pb-10"></div>
        <div>2023 年 5 月 20 日制定・施行</div>
        <div class="pb-10"></div>
      </div>
    </div>
  </div>
</template>
