<template>
  <div class="home">
    <div class="mx-auto w-3/4 text-left">
      <div class="pb-8"></div>
      <div class="text-xl font-bold">特定商取引法に基づく表記</div>
      <div class="pb-7"></div>

      <div class="text-base">
        <table cellpadding="5px">
          <tr>
            <th class="th_space">販売事業者</th>
            <th>株式会社 Culture Connect</th>
          </tr>
          <tr>
            <th class="th_space">責任者</th>
            <th>株式会社 Culture Connect 代表取締役 風早完次</th>
          </tr>
          <tr>
            <th class="th_space">所在地</th>
            <th>
              〒604-0826
              <div>
                京都府京都市中京区高倉通御池上る柊町581イーグルコート御所南903
              </div>
            </th>
          </tr>
          <tr>
            <th class="th_space">お問い合わせ</th>
            <th>info@cultureconnect.co.jp</th>
          </tr>
          <tr>
            <th class="th_space">ホームページ</th>
            <a href="https://cultureconnect.co.jp"
               target="_blank"
               rel="noopener noreferrer"                                                                        
               >
              <th>https://cultureconnect.co.jp</th>
            </a>
          </tr>
          <tr>
            <th class="th_space">販売価格</th>
            <th>
              購入手続きの際に画面に表示されます。消費税は内税として表示しています。
            </th>
          </tr>
          <tr>
            <th class="th_space">販売価格以外に必要な費用</th>
            <th>
              情報端末の購入費、インターネット接続料金、通信料金、Ethereum
              トランザクション実行手数料（Gas代）等はお客様のご負担になります。
            </th>
          </tr>
          <tr>
            <th class="th_space">お支払方法</th>
            <th>Ethereum による決済。注文時に支払いが確定します。</th>
          </tr>
          <tr>
            <th class="th_space">引き渡し時期</th>
            <th>Ethereum 決済が完了したとき</th>
          </tr>
          <tr>
            <th class="th_space">返品・キャンセル等</th>
            <th>
              商品の特性上、購入後の返品/キャンセルはできません。
              <div>
                正常にサービスが利用できなかった場合、お問い合わせ窓口よりご連絡ください。
              </div>
              <div>
                情報端末の購入費、インターネット接続料金、通信料金、Ethereumトランザクション実行手数料（Gas代）等はお客様のご負担になります。
              </div>
            </th>
          </tr>
          <tr>
            <th class="th_space">提供時期</th>
            <th>Ethereum トランザクション承認が確認されてから速やかに</th>
          </tr>
          <tr>
            <th class="th_space">お支払方法</th>
            <th>Ethereum</th>
          </tr>
          <tr>
            <th class="th_space">返品・キャンセル等</th>
            <th>
              商品の特性上、購入後の返品/キャンセルはできません。
              <div>
                正常にサービスが利用できなかった場合、info@cultureconnect.co.jpにご連絡ください。
              </div>
            </th>
          </tr>
        </table>

        <div class="pb-8"></div>
      </div>
    </div>
  </div>
</template>

<style>
th,
td {
  padding: 10px; /* 余白指定 */
}

@media screen and (max-width: 767px) {
  .th_space {
    width: 120px;
  }
}
</style>
