<template>
  <div>
    <div class="text-xl font-bold">LAIDBACK LU ROADMAP</div>
    <div class="pb-4"></div>
    <div class="text-lg font-bold">WHAT WE HAVE DONE:</div>
    <div class="text-lg">
      An animation series Bible and a pilot episode animation are produced.
    </div>
    <div class="pb-4"></div>
    <div class="text-lg font-bold">WHAT WE DO NEXT:</div>
    <div class="text-lg">
      Release full on-chain NFT to produce a picture book.
    </div>
    <div class="pb-4"></div>
    <div class="text-lg font-bold">THEN:</div>
    <div class="text-lg">
      Keep releasing full on-chain NFT to produce a related picture book.
    </div>
    <div class="pb-4"></div>
    <div class="text-lg font-bold">ULTIMATELY:</div>
    <div class="text-lg">Produce Laidback Lu animation series.</div>
    <div class="text-lg">
      License Laidback Lu merchandising rights to release various Laidback Lu
      products!
    </div>
  </div>
</template>
