<template>
  <div class="items2">
    <div class="item-text">
      <div class="text-left text-xl font-bold">
        OUR VISION: WEB 3 BASE DECENTRALIZED BUSINESS BETWEEN CREATORS AND USERS
      </div>
      <div class="pb-2"></div>
      <div class="text-left text-lg">
        Thanks to web 3 technology, creators and engineers can make business
        directly with users. And offering full on-chain NFT is the best to
        obtain the credit of the buyers to the creators.
      </div>
      <div class="h-6"></div>
      <div class="text-left text-xl font-bold">
        LAIDBACK LU FULL ON-CHAIN NFT!
      </div>
      <div class="pb-2"></div>
      <div class="text-left text-lg">
        An Innovative full on-chain “Laidback Lu” illustration and Animation NFT
        public sales will take place at 5 p.m., Saturday, May 20th EST!
      </div>
    </div>

    <div class="item-png">
      <img src="/images/photo1.png" />
    </div>
  </div>
</template>

<style>
.items2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px; /*縦・横で同じ余白*/
}

.items2 .item-text {
  width: 60%;
}

.items2 .item-png {
  width: 25%;
}
@media screen and (max-width: 767px) {
  .items2 {
    flex-direction: column;
  }
  .items2 .item-text {
    width: 100%;
  }

  .items2 .item-png {
    width: 100%;
  }
}
</style>
