<template>
  <div>
    <div class="text-xl font-bold">STORY</div>
    <div class="pb-2"></div>
    <div>Lu is a young ambitious tea maker.</div>
    <div class="pb-1"></div>
    <div>
      Lu’s family inherits an extraordinary power to make something called “Tea
      of Life”, which tastes different from rabbit to rabbit, depending on their
      life experiences.
    </div>
    <div class="pb-1"></div>
    <div>
      All rabbits want to drink this “Tea of Life” at least once in their
      lifetime. Many may say this is power is just like magic, but it is not. A
      tea maker has to experience lots of things and emotions to make tea to
      obtain the wisdom of life.
    </div>
    <div class="pb-1"></div>
    <div>
      Lu's family believes that learning these elements from life, on your own,
      instead of being taught by someone, is necessary; hence every tea maker
      from the family needs to go on a journey.
    </div>
    <div class="pb-1"></div>
    <div>
      Lu travels from town to town to meet people, experience emotions and
      relationships. Lu will experience many kinds of sweet tasted love,
      friendships, happiness, and sympathy, but sometimes he will also
      experience bitter tasted emotions like sadness or anger.
    </div>
    <div class="pb-1"></div>
    <div>
      By meeting all kinds of emotions and experiences, Lu can collect the
      essence of Tea of Life.
    </div>
  </div>
</template>
