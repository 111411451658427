import languages from "./languages";

const lang = {
  menu: {
    connect: "接続",
    nometamask: "メタマスクがありません",
  },
  header: {
    lu: "へろへろうさぎのルー",
    pictureBook: "絵本",
    shop: "ショップ",
    home: "ホーム",
  },
  footer: {
    home: "ホーム",
    termofservice: "利用規約",
    notation: "特定商取引法に基づく表記",
    privacy: "プライバシーポリシー",
  },
  mint: {
    switchNetwork: "ネットワークを切り替えて下さい。",
    switchNetworkButton: "ネットワーク切り替え",
    connectMetamask: "Metamaskと接続してください。",
    mint: "Mint",
    mintAlertTitle: "注）ショップのページには、ミントされたNFTがリストで表示されています。",
    mintAlertBody: "水色のMintボタンを押して、Mintを完了すると、新たにショップのページに、そのNFTがリストとして加わります。リビール方式なので、Mintを完了して初めて購入したデザインを確認して頂けます。",
  },
  message: {
    notFoundMessage: "ページがありません",
    touchToUpdate: "イメージをクリックすると再度生成します。",
    processing: "Processing...",
    resources: "Resources",
  },
  languages,
};

export default lang;
