
import { defineComponent, reactive, onMounted } from "vue";
import { useStore } from "vuex";

import { auth } from "@/utils/firebase";
// import { User } from "firebase/auth";

import { useI18nParam } from "@/i18n/utils";
import Header1 from "@/components/Header1.vue";
import Footer1 from "@/components/Footer1.vue";

export default defineComponent({
  name: "AppLayout",
  components: {
    Header1,
    Footer1,
  },
  async setup() {
    const store = useStore();
    useI18nParam();

    /*
    const user = reactive<UserData>({ user: null });

    onMounted(() => {
      auth.onAuthStateChanged((fbuser) => {
        if (fbuser) {
          console.log("authStateChanged:");
          user.user = fbuser;
          store.commit("setUser", fbuser);
        } else {
          store.commit("setUser", null);
        }
      });
    });
*/
    return {
      //      user,
    };
  },
});
