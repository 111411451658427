import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "font-londrina font-yusei ml-16" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  type: "button",
  class: "inline-block rounded px-6 py-2.5 leading-tight text-gray-500 shadow-md",
  disabled: ""
}
const _hoisted_5 = { class: "ml-10" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  type: "button",
  class: "inline-block rounded px-6 py-2.5 leading-tight text-gray-500 shadow-md",
  disabled: ""
}
const _hoisted_8 = { class: "ml-10" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = {
  disabled: "",
  class: "inline-block rounded bg-gray-400 px-6 py-2.5 leading-tight text-white shadow-md"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    (_ctx.hasMetaMask)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          (_ctx.account)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                (_ctx.isBusy)
                  ? (_openBlock(), _createElementBlock("button", _hoisted_4, [
                      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("message.processing")), 1)
                    ]))
                  : (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.signIn && _ctx.signIn(...args))),
                      class: "inline-block rounded bg-green-600 px-6 py-2.5 leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg"
                    }, _toDisplayString(_ctx.$t("menu.connected")), 1))
              ]))
            : (_openBlock(), _createElementBlock("span", _hoisted_6, [
                (_ctx.isBusy)
                  ? (_openBlock(), _createElementBlock("button", _hoisted_7, [
                      _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("message.processing")), 1)
                    ]))
                  : (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.connect && _ctx.connect(...args))),
                      class: "inline-block rounded bg-green-500 px-6 py-2.5 leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg"
                    }, _toDisplayString(_ctx.$t("menu.connect")), 1))
              ]))
        ]))
      : (_openBlock(), _createElementBlock("span", _hoisted_9, [
          _createElementVNode("button", _hoisted_10, _toDisplayString(_ctx.$t("menu.nometamask")), 1)
        ]))
  ]))
}