export const firebaseConfig = {
  apiKey: "AIzaSyDye4Ko22Dh8Siwb1yETkAQrnCmEcOOrnw",
  authDomain: "laidbacklu-web.firebaseapp.com",
  projectId: "laidbacklu-web",
  storageBucket: "laidbacklu-web.appspot.com",
  messagingSenderId: "830552021583",
  appId: "1:830552021583:web:e8430bdbf6762a3f29a83a",
  measurementId: "G-EDN4QN39WS",
};

export const network = "mainnet";
export const tokenAddress = "0xac644987601456554272b296936d3c262a7a1fcf";

