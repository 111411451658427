<template>
  <div>
    <div class="text-xl font-bold">へろへろうさぎのルーのロードマップ</div>
    <div class="pb-4"></div>
    <div class="text-lg font-bold">私たちがしたこと:</div>
    <div class="text-lg">
      アニメーションシリーズ製作に向けて、企画書とパイロット映像を製作しました。
    </div>
    <div class="pb-4"></div>
    <div class="text-lg font-bold">次に私たちが取り組むこと:</div>
    <div class="text-lg">
      フルオンチェーンのNFTをリリースし、それに関連する絵本を製作します。
    </div>
    <div class="pb-4"></div>
    <div class="text-lg font-bold">そして:</div>
    <div class="text-lg">
      フルオンチェーンのNFTと関連する絵本の製作を継続します。
    </div>
    <div class="pb-4"></div>
    <div class="text-lg font-bold">究極的には:</div>
    <div class="text-lg">
      この絵本を世界で出版し、『へろへろうさぎのルー』を広げていくことで、様々な商品展開も進めて、アニメーション作品の製作を目指していきます。
    </div>
  </div>
</template>
