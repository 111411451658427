
import { defineComponent } from "vue";
import Languages from "./Languages.vue";

export default defineComponent({
  components: {
    Languages,
  },
  setup() {
    return {};
  },
});
