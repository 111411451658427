import { ref, computed } from "vue";
import { useStore } from "vuex";
import { ethers, BigNumber } from "ethers";


type Provider =
  | ethers.providers.JsonRpcProvider
  | ethers.providers.AlchemyProvider
  | ethers.providers.InfuraProvider;
type ProviderOrSigner = ethers.providers.Provider | ethers.Signer | undefined;

export const useUser = () => {
  const store = useStore();
  const user = computed(() => store.state.user);
  return user;
};
export const useIsSignedIn = () => {
  const store = useStore();
  const isSignedIn = computed(() => store.getters.isSignedIn);
  return isSignedIn;
};


export const getAddresses = (network: string, contentAddress: string) => {
  const EtherscanBase = (() => {
    if (network == "rinkeby") {
      return "https://rinkeby.etherscan.io/address";
    } else if (network == "goerli") {
      return "https://goerli.etherscan.io/address";
    } else if (network == "mumbai") {
      return "https://mumbai.polygonscan.com/address";
    }
    return "https://etherscan.io/address";
  })();
  const OpenSeaBase = (() => {
    if (network == "rinkeby") {
      return "https://testnets.opensea.io/assets/rinkeby";
    } else if (network == "goerli") {
      return "https://testnets.opensea.io/assets/goerli";
    } else if (network == "mumbai") {
      return "https://testnets.opensea.io/assets/mumbai";
    }
    return "https://opensea.io/assets/ethereum";
  })();
  const EtherscanToken = `${EtherscanBase}/${contentAddress}`;
  const OpenSeaPath = `${OpenSeaBase}/${contentAddress}`;

  return {
    EtherscanBase,
    OpenSeaBase,
    EtherscanToken,
    OpenSeaPath,
  };
};

export const _useNetworkContext = (
  chainId: string,
  tokenAddress: string,
  func: (address: string, provider: ProviderOrSigner) => ethers.Contract
) => {
  const store = useStore();

  const networkContext = computed(() => {
    const signer = store.getters.getSigner(chainId);
    console.log(signer, chainId);
    if (signer) {
      const contract = func(tokenAddress, signer);
      return { signer, contract };
    }
    return null;
  });

  return {
    networkContext,
  };
};

const providerToken = {
  wabi: require("@/abis/LuToken.json"), // wrapped abi
};

export const getProvider = (
  network: string,
  alchemyKey: string | undefined
) => {
  return network == "localhost"
    ? new ethers.providers.JsonRpcProvider()
    : network == "mumbai"
    ? new ethers.providers.JsonRpcProvider(
        "https://matic-mumbai.chainstacklabs.com"
      )
    : alchemyKey
    ? new ethers.providers.AlchemyProvider(network, alchemyKey)
    : new ethers.providers.InfuraProvider(network);
};

export const getTokenContract = (
  address: string,
  provider: ProviderOrSigner
): ethers.Contract => {
  const tokenContract = new ethers.Contract(
    address,
    providerToken.wabi.abi,
    provider
  );
  return tokenContract;
};

export const useTokenNetworkContext = (
  chainId: string,
  tokenAddress: string
) => {
  return _useNetworkContext(chainId, tokenAddress, getTokenContract);
};


// for item list
export const getCurrentToken = async (tokenContract: ethers.Contract) => {
  const total = await tokenContract.totalSupply();
  const totalNum = total.toNumber();
  return totalNum - 1;
};

export const getTokenData = async (tokenContract: ethers.Contract, i: number) => {
  const tokenBase64 = await tokenContract.tokenURI(i);
  return JSON.parse(atob(tokenBase64.split(",")[1]));
};

export const useTokenList = (tokenContract: ethers.Contract, useCache: boolean) => {
  const items = ref<{[key: string]: {name: string, image: string, tokenId: number}}>({})

  const updateTokenFromCurrent = async () => {
    const currentToken = await getCurrentToken(tokenContract);
    const num = 10;
    for(let i = currentToken; i > currentToken - num && i > 0; i -- ) {
      // TODO local storage cache
      items.value[i] = await getTokenData(tokenContract, i);
      items.value[i].tokenId = currentToken;
    }
  }
  const sortedItem = computed(() => {
    return Object.keys(items.value)
      .sort((a, b) => {
        return Number(a) > Number(b) ? -1 : 1;
      }).map(k => items.value[k]);
  });
  return {
    items,
    updateTokenFromCurrent,
    sortedItem,
  };
};


// for mint
export const useMint = (networkContext: any, mintCost: string, mintErrorCallback: (e: any) => void) => {
  const minting = ref(false);
  const mint = async () => {
    if (networkContext.value == null) {
      return;
    }
    const { contract } = networkContext.value;
    minting.value = true;
    try {
      const tx = await contract.mint({value: ethers.utils.parseEther(mintCost)});
      const result = await tx.wait();
    } catch (e) {
      minting.value = false;
      mintErrorCallback(e);
    }
  };
  return {
    minting,
    mint,
  };
};
