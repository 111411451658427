
import { defineComponent } from "vue";
import { initializeEthereum } from "./utils/MetaMask";
import detectEthereumProvider from "@metamask/detect-provider";

export default defineComponent({
  setup() {
    (async () => {
      const provider = await detectEthereumProvider();
      console.log(provider);
    })();

    initializeEthereum();
    return {};
  },
});
