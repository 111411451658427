
import { defineComponent, computed } from "vue";

import HomeTop from "@/views/Home/en/Top.vue";
import HomeStory from "@/views/Home/en/Story.vue";
import HomeAboutLu from "@/views/Home/en/AboutLu.vue";
import HomeVideo from "@/views/Home/en/Video.vue";
import HomeCollection from "@/views/Home/en/Collection.vue";
import HomeRoadmap from "@/views/Home/en/Roadmap.vue";
import HomeQuestion from "@/views/Home/en/Question.vue";
import HomeCopyright from "@/views/Home/en/Copyright.vue";

import HomeTopJa from "@/views/Home/ja/Top.vue";
import HomeStoryJa from "@/views/Home/ja/Story.vue";
import HomeAboutLuJa from "@/views/Home/ja/AboutLu.vue";
import HomeVideoJa from "@/views/Home/ja/Video.vue";
import HomeCollectionJa from "@/views/Home/ja/Collection.vue";
import HomeRoadmapJa from "@/views/Home/ja/Roadmap.vue";
import HomeQuestionJa from "@/views/Home/ja/Question.vue";
import HomeCopyrightJa from "@/views/Home/ja/Copyright.vue";

// import Mint from "@/views/Mint.vue";
// import Mint from "@/components/TokenGates/Mint.vue";

import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    // Mint

    HomeTop,
    HomeStory,
    HomeAboutLu,
    HomeVideo,
    HomeCollection,
    HomeRoadmap,
    HomeQuestion,
    HomeCopyright,

    HomeTopJa,
    HomeStoryJa,
    HomeAboutLuJa,
    HomeVideoJa,
    HomeCollectionJa,
    HomeRoadmapJa,
    HomeQuestionJa,
    HomeCopyrightJa,
  },
  setup() {
    const i18n = useI18n();
    const lang = computed(() => {
      return i18n.locale.value;
    });

    return { lang };
  },
});
