<template>
  <div>
    <div class="text-xl font-bold">QUESTIONS</div>
    <div class="pb-5"></div>
    <div class="text-lg font-bold">- What is full on-chain NFT?</div>
    <div class="text-lg">
      Major NFTs are stored off-chain. Even if NFTs stored on a redundant
      Inter-Plantary File System (IPFS) setup, there’re risks of disappearance
      of the artworks, if a) weak access to IPFS, b) the issuer stops paying the
      primary server to store the artworks. Since we deploy the artworks on the
      smart contract, there’re no such risks anymore. Your purchased NFT
      artworks are fully secured!
    </div>
    <div class="pb-5"></div>
    <div class="text-lg font-bold">- Where’re the full on-chain NFT?</div>
    <div class="text-lg">Laidback Lu is on Ethereum layer 1.</div>
    <div class="pb-5"></div>
    <div class="text-lg font-bold">- How many NFT shall be released?</div>
    <div class="text-lg">
      There’ll be 360 generative NFT and they are the part of the picture book
      that shall be delivered to you upon request.
    </div>
    <div class="pb-5"></div>
    <div class="text-lg font-bold">
      - What is the relationship between the gift and the number of NFTs purchased?
    </div>
    <div class="text-lg">
      If you purchase one or more NFTs from us, not at the secondary market, and mint them, you are eligible to receive a PDF digest of the picture book. (You should inform us of your email address to send it in advance.)
    </div>
    <div class="h-2"></div>
    <div class="text-lg">
      If the number of NFTs purchased at the primary sales is between one and five (by wallet) and mint them, we will also provide Thank You Cards (PDF) according to the number of NFTs purchased. As we will create 5 different Thank You Cards, NFT purchasers are eligible to receive the all 5 Thank You Cards if you mint 5 or more by wallet.
    </div>
    <div class="text-lg">
      We randomly select the Thank You Cards and deliver them to you. So, you cannot select them.
    </div>
    <div class="h-2"></div>
    <div class="text-lg">
      If you purchase six or more NFTs at the primary sales and mint them, we will provide you with a PDF of the full version of the picture book (You should inform us of your email address to send it in advance.) along with the 5 different Thank You Cards.
    </div>
    <div class="h-2"></div>
    <div class="text-lg">
     If you purchase 10 or more NFTs at the primary market and mint them, and you are in Japan, USA, Canada, Korea, Taiwan, Hong Kong, Australia, New Zealand, UK, France, Spain, Italy, or Germany, we will send a printed copy of the book to the address you provide. (A PDF of the full version of the book and 5 Thank You Cards will be sent to the email address you provide.)
    </div>
    <div class="h-2"></div>
    <div class="text-lg">
      Please note that we will not deliver any of the above if you do not agree with disclosing your personal information (email address, mailing address, name).
    </div>
    <div class="text-lg">
      If you are eligible to receive the gift(s), please send an message to our email address with stating your wallet ID. (Available at #NFT holders channel of Laidback Lu discord: <a href="https://discord.com/channels/1088335404014501908/1093178245391855706." class="underline">https://discord.com/channels/1088335404014501908/1093178245391855706.</a>
    </div>
    <div class="text-lg">
      If you are eligible to receive the printed picture book and live in the regions mentioned above, please inform us of your address and your name as well in your message to our email address.
    </div>
    <div class="text-lg">
      We confirm your eligibility to deliver the gift(s) once we confirm it.
    </div>
    <div class="pb-5"></div>
    <div class="text-lg font-bold">- What is the mint price going to be?</div>
    <div class="text-lg">
      Each full on-chain NFT will cost 0.01ETH+ a very efficient gas fee to
      mint.
    </div>
    <div class="pb-5"></div>
    <div class="text-lg font-bold">- How many NFTs can I mint?</div>
    <div class="text-lg">We will not set the limit per wallet address.</div>
    <div class="pb-5"></div>
    <div class="text-lg font-bold">- Can I be on the mint-list?</div>
    <div class="text-lg">
      We will not have the mint-list and open to the public directly. But we<a
        class="border-b border-solid"
        target="_blank"
        rel="noopener noreferrer"                                                                        
        href="https://twitter.com/laidback_lu"
      >
        Laidback Lu twitter.</a
      >
    </div>
    <div class="pb-5"></div>
    <div class="text-lg font-bold">
      - How can I complete the purchase as I wish?
    </div>
    <div class="text-lg">
      Please go to Laidback Lu mint site at or after 5 p.m., 19th May, 2023, EST
      (US East Coast Time). Or, please go to OpenSea at 5 p.m. , 20th May, 2023,
      EST (US East Coast Time). Details will be announced on
      <a class="border-b border-solid" href="https://twitter.com/laidback_lu"
        target="_blank"
        rel="noopener noreferrer"                                                                        
        >Laidback Lu twitter.</a
      >
    </div>
  </div>
</template>
